
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import commonUtil from '@/services/CommonUtil';
import axiosService from '@/services/AxiosService'
import { Communication, Company } from '@/model'
import { AxiosResponse } from 'axios';
import moment from 'moment'


interface CommunicationExtend extends Communication
{
  owners: string | null;
  participants: string | null;
}

interface CommunicationSummary
{
   owners: string | null;
   headDepartments: string | null;
   participants: string | null;
   hasPotentialQuestions: boolean;
}


export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const companyId = ref('')
    const record = reactive({} as Company)
    const communications = ref<Array<CommunicationExtend>>()
    const communicationsSummary = ref({} as CommunicationSummary )
    onMounted(() => {
      companyId.value = route.params.companyId as string
      if (route.params.companyId) {
        axiosService.get(`odata/companys/${route.params.companyId}`).then(res => {
          if (res.data) {
            record.id = res.data.id
            record.companyName = res.data.companyName
            record.industryTypeId = res.data.industryTypeId
            record.registeredCapital = res.data.registeredCapital
            record.approximateTaxInComming = res.data.approximateTaxInComming
            record.approximateBusinessIncomming = res.data.approximateBusinessIncomming
            record.projectStatus = res.data.projectStatu
            record.memo = res.data.memo
            record.companySource = res.data.companySource
            record.companyType = res.data.companyType
            record.projectType = res.data.projectType
            record.investors = res.data.investors
          }
        })

        axiosService.get(`odata/communications?$expand=CommunicationParticipants($select=participantId,regardingParticipantType;$expand=Participant($select=id,fullName))&$filter=companyId eq ${route.params.companyId}&$orderby=communicateOn desc`)
          .then((res: AxiosResponse<{ value: Array<CommunicationExtend>}>) => {
          res.data.value.forEach(c => {
              c.owners = c.communicationParticipants.filter(p => p.regardingParticipantType == 1)
                .map(p => p.participant.fullName)
                .filter((value, index, self) => self.indexOf(value) === index)
                .join(', ')
              c.participants = c.communicationParticipants.filter(p => p.regardingParticipantType == 2)
                .map(p => p.participant.fullName)
                .filter((value, index, self) => self.indexOf(value) === index)
                .join(', ')
              if(c.communicateOn){
                c.communicateOn = moment(c.communicateOn).format('yyyy/MM/DD')
              }
          })
          communications.value = res.data.value
          const ownerNames = [] as Array<string>
          const participantNames = [] as Array<string>
          const headDepartments = [] as Array<string>
          communications.value.forEach(c => {
            ownerNames.push(...c.communicationParticipants.filter(p => p.regardingParticipantType == 1).map(p => p.participant.fullName))
            participantNames.push(...c.communicationParticipants.filter(p => p.regardingParticipantType == 2).map(p => p.participant.fullName))
            headDepartments.push(c.headDepartment)
          })
          communicationsSummary.value = {
            owners: ownerNames.filter((value, index, self) => self.indexOf(value) === index).join(', '),
            participants: participantNames.filter((value, index, self) => self.indexOf(value) === index).join(', '),
            headDepartments: headDepartments.filter((value, index, self) => self.indexOf(value) === index).join(', '),
            hasPotentialQuestions: communications.value.some(c => c.potentialQuestions)
          }
          console.log(communicationsSummary.value)

        })
      }
    })

    return {
      isMobile: commonUtil.isMobile(),
      router,
      route,
      record,
      communications,
      communicationsSummary,
    }
  },
})
