<template>
  <div class="communication-summary px-2 py-2">
    <div class="row mb-3 justify--space-between">
      <div class="flex align-self--center">
        <va-breadcrumbs>
          <va-breadcrumbs-item label="项目基本信息" />
          <va-breadcrumbs-item label="跟进明细" />
        </va-breadcrumbs>
      </div>
      <div class="justify-self--end">
        <va-button :rounded="false" size="small" class="px-2" @click=" router.back()" outline flat>返回</va-button>
      </div>
    </div>
    <template v-for="c in communications" :key="c.id">
      <div v-if="isMobile && communications &&communications.length > 0">
        <div class="sub-title">沟通记录</div>
        <div class="flex xs12">
          <va-input label="具体负责人" disabled outline v-model="c.owners" />
        </div>
        <div class="flex xs12">
          <va-input label="牵头部门" disabled outline v-model="c.headDepartment"/>
        </div>
        <div class="flex xs12">
          <va-input label="配合人员" disabled outline v-model="c.participants"/>
        </div>
        <div class="flex xs12">
          <va-input label="沟通时间" disabled outline  v-model="c.communicateOn"/>
        </div>
        <div class="flex xs12">
          <va-input label="存在问题" disabled outline v-model="c.potentialQuestions" />
        </div>
        <div class="flex xs12">
          <va-input class="" label="沟通记录" outline type="textarea" :min-rows="5" :max-rows="10" v-model="c.feedbackAfterContact" disabled />
        </div>
      </div>
    </template>
    <div v-if="!isMobile && communications && communications.length > 0" class="summary-desktop">
      <div class="box mt-3">
        <div class="row">
          <div class="sub-title flex md12">沟通记录</div>
        </div>
        <div class="row">
          <div class="md2 flex label">具体负责人</div>
          <div class="md10 flex">{{communicationsSummary.owners}}</div>
        </div>
        <div class="row">
          <div class="flex md2 label">牵头部门</div>
          <div class="flex md2">{{communicationsSummary.headDepartments}}</div>
          <div class="flex md2 label">配合人员</div>
          <div class="flex md6">{{communicationsSummary.participants}}</div>
        </div>
        <div class="row">
          <div class="flex md12 label">存在问题</div>
        </div>
        <div class="row" v-if="communicationsSummary.hasPotentialQuestions">
          <div class="flex md12 py-2">
            <template v-for="c in communications" :key="c.id">
              <h6 class="mb-1" v-if="c.potentialQuestions != ''">{{c.communicateOn}} </h6>
              <p class="mb-2" v-if="c.potentialQuestions != ''"> {{c.potentialQuestions}}</p>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="flex md12 label">进展情况</div>
        </div>
        <div class="row">
          <div class="flex md12 py-2">
            <template v-for="c in communications" :key="c.id">
              <h6 class="mb-1">{{c.communicateOn}} </h6>
              <p class="mb-2"> {{c.feedbackAfterContact}}</p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <va-alert color="info" border="top" border-color="info" class="mb-4" v-if="communications && communications.length === 0">
      <template #icon>
        <va-icon name="info" />
      </template>
      暂无沟通记录
    </va-alert>
    <div class="my-3">
      <va-button :rounded="false" size="small" class="px-2 button-cancel-bottom" @click=" router.back()" outline flat v-if="communications && communications.length > 0">返回</va-button>
    </div>
  </div>
  
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import commonUtil from '@/services/CommonUtil';
import axiosService from '@/services/AxiosService'
import { Communication, Company } from '@/model'
import { AxiosResponse } from 'axios';
import moment from 'moment'


interface CommunicationExtend extends Communication
{
  owners: string | null;
  participants: string | null;
}

interface CommunicationSummary
{
   owners: string | null;
   headDepartments: string | null;
   participants: string | null;
   hasPotentialQuestions: boolean;
}


export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const companyId = ref('')
    const record = reactive({} as Company)
    const communications = ref<Array<CommunicationExtend>>()
    const communicationsSummary = ref({} as CommunicationSummary )
    onMounted(() => {
      companyId.value = route.params.companyId as string
      if (route.params.companyId) {
        axiosService.get(`odata/companys/${route.params.companyId}`).then(res => {
          if (res.data) {
            record.id = res.data.id
            record.companyName = res.data.companyName
            record.industryTypeId = res.data.industryTypeId
            record.registeredCapital = res.data.registeredCapital
            record.approximateTaxInComming = res.data.approximateTaxInComming
            record.approximateBusinessIncomming = res.data.approximateBusinessIncomming
            record.projectStatus = res.data.projectStatu
            record.memo = res.data.memo
            record.companySource = res.data.companySource
            record.companyType = res.data.companyType
            record.projectType = res.data.projectType
            record.investors = res.data.investors
          }
        })

        axiosService.get(`odata/communications?$expand=CommunicationParticipants($select=participantId,regardingParticipantType;$expand=Participant($select=id,fullName))&$filter=companyId eq ${route.params.companyId}&$orderby=communicateOn desc`)
          .then((res: AxiosResponse<{ value: Array<CommunicationExtend>}>) => {
          res.data.value.forEach(c => {
              c.owners = c.communicationParticipants.filter(p => p.regardingParticipantType == 1)
                .map(p => p.participant.fullName)
                .filter((value, index, self) => self.indexOf(value) === index)
                .join(', ')
              c.participants = c.communicationParticipants.filter(p => p.regardingParticipantType == 2)
                .map(p => p.participant.fullName)
                .filter((value, index, self) => self.indexOf(value) === index)
                .join(', ')
              if(c.communicateOn){
                c.communicateOn = moment(c.communicateOn).format('yyyy/MM/DD')
              }
          })
          communications.value = res.data.value
          const ownerNames = [] as Array<string>
          const participantNames = [] as Array<string>
          const headDepartments = [] as Array<string>
          communications.value.forEach(c => {
            ownerNames.push(...c.communicationParticipants.filter(p => p.regardingParticipantType == 1).map(p => p.participant.fullName))
            participantNames.push(...c.communicationParticipants.filter(p => p.regardingParticipantType == 2).map(p => p.participant.fullName))
            headDepartments.push(c.headDepartment)
          })
          communicationsSummary.value = {
            owners: ownerNames.filter((value, index, self) => self.indexOf(value) === index).join(', '),
            participants: participantNames.filter((value, index, self) => self.indexOf(value) === index).join(', '),
            headDepartments: headDepartments.filter((value, index, self) => self.indexOf(value) === index).join(', '),
            hasPotentialQuestions: communications.value.some(c => c.potentialQuestions)
          }
          console.log(communicationsSummary.value)

        })
      }
    })

    return {
      isMobile: commonUtil.isMobile(),
      router,
      route,
      record,
      communications,
      communicationsSummary,
    }
  },
})
</script>
<style lang="scss" scoped>
.communication-summary {
  min-height: 100%;
  background-color: #fff;
  position: relative;
  font-size: .8rem;
  > .sub-title {
      font-size: 1rem;
      font-weight: 600;
      padding-left: 0.3rem;
      border-bottom: 2px dashed #3976b7;
      padding-bottom: 0.4rem;
      margin-bottom: 1.2rem;
      &::before{
        position: absolute;
        content: "";
        display: block;
        width: 0.15rem;
        height: 1rem;
        left: 0;
        background-color: #2c82e0;
      }
  }

  .summary-desktop
  {
    display: flex;
    justify-content: center;
    .box {
      font-size: .9rem;
      width: 90rem;
      .row {
        line-height: 2rem;
        min-height: 2.67rem;
        width: 100%;
        margin:0;
        & > div {
            border: 1px dashed #333;
            font-size: 0.9rem;
            padding: .3rem .5rem;
            &.label
            {
              font-weight: 600;
              background-color: #f6f7f9;
            }
        }
        .sub-title{
          font-size: .9rem;
          font-weight: 600;
          color: #3b58c1;
          background-color: #e9eaea;
        }
      }
    }
  }
  .button-cancel-bottom
  {
     background-color: #154fc11a !important;
  }
}
</style>